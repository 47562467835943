import { AfterViewInit, Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ModalController, AlertController, NavParams } from '@ionic/angular'
import { analytics } from 'firebase'
import { CotacoesService } from 'src/app/web/services/Quotation.service'
import { environment } from '../../../../../../../environments/environment'
import { LoaderComponent } from 'src/shared/loader/loader.component'
import { TermoCotacaoModal } from '../termo-cotacao/termo-cotacao.modal'

@Component({
	selector: 'app-new-cotacao',
	templateUrl: './new-cotacao.modal.html',
	styleUrls: ['./new-cotacao.modal.scss'],
})
export class NewCotacaoModal implements AfterViewInit {
	actualDate = new Date()
	newCotacaoForm = this.fb.group({
		quoteType: ['short', Validators.required],
		livreGroup: [],
		submercado: [null, Validators.required],
		tipoEnergia: [null, Validators.required],
		inicioSuprimento: [null, Validators.required],
		finalSuprimento: [null],
		sazoInferior: ['0'],
		sazoSuperior: ['0'],
		flexInferior: ['0'],
		flexSuperior: ['0'],
		modulacao: ['nao'],
		contraparte: [null, Validators.required],
		unidadeLote: [null, Validators.required],
		pontaCliente: [null, Validators.required],
		fixoAgio: [null, Validators.required],
		lote: [null, Validators.required],
		mandarAte: [],
		validadeCliente: [],
		observacao: [null],
		uuid: [],
		refresh: [false],
		proposalCode: [],
		term: [false, Validators.requiredTrue],
	})
	group: string
	companiesList = []
	cotacaoRefresh: any

	constructor(
		public modalController: ModalController,
		public fb: FormBuilder,
		public quotationService: CotacoesService,
		public alertController: AlertController,
		public loaderComponent: LoaderComponent,
		public navParams: NavParams
	) {
		this.actualDate.setHours(this.actualDate.getHours() + 1)
		this.newCotacaoForm.controls['mandarAte'].setValue(
			this.actualDate.toISOString()
		)
		this.newCotacaoForm.controls['validadeCliente'].setValue(
			this.actualDate.toISOString()
		)
		this.group = navParams.get('group')
		this.companiesList = navParams.get('companiesList')
		this.cotacaoRefresh = navParams.get('cotacao')
			? navParams.get('cotacao')
			: [{}]
		if (!!this.cotacaoRefresh.uuid) {
			let inicioFornecimento =
				this.cotacaoRefresh.inicioSuprimento.split('T')[0]
			let finalFornecimento = this.cotacaoRefresh.finalSuprimento.split('T')[0]
			let anoInicio = inicioFornecimento.split('-')[0]
			let mesInicio = inicioFornecimento.split('-')[1]
			let anoFinal = finalFornecimento.split('-')[0]
			let mesFinal = finalFornecimento.split('-')[1]
			let tipoProposta =
				`${mesInicio}/${anoInicio}` === `${mesFinal}/${anoFinal}`
					? 'short'
					: 'long'
			this.newCotacaoForm.controls['inicioSuprimento'].setValue(
				this.cotacaoRefresh.inicioSuprimento
			)
			this.newCotacaoForm.controls['finalSuprimento'].setValue(
				this.cotacaoRefresh.finalSuprimento
			)
			this.newCotacaoForm.controls['quoteType'].setValue(tipoProposta)
			this.newCotacaoForm.controls['contraparte'].setValue(
				this.cotacaoRefresh.contraparte
			)
			this.newCotacaoForm.controls['submercado'].setValue(
				this.cotacaoRefresh.submercado.toString()
			)
			this.newCotacaoForm.controls['tipoEnergia'].setValue(
				this.cotacaoRefresh.tipoEnergia.toString()
			)
			this.newCotacaoForm.controls['pontaCliente'].setValue(
				this.cotacaoRefresh.pontaCliente.toString()
			)
			this.newCotacaoForm.controls['fixoAgio'].setValue(
				this.cotacaoRefresh.fixoAgio.toString()
			)
			this.newCotacaoForm.controls['unidadeLote'].setValue(
				this.cotacaoRefresh.unidadeLote.toString()
			)
			this.newCotacaoForm.controls['sazoInferior'].setValue(
				this.cotacaoRefresh.sazoInferior.toString()
			)
			this.newCotacaoForm.controls['sazoSuperior'].setValue(
				this.cotacaoRefresh.sazoInferior.toString()
			)
			this.newCotacaoForm.controls['flexInferior'].setValue(
				this.cotacaoRefresh.flexInferior.toString()
			)
			this.newCotacaoForm.controls['flexSuperior'].setValue(
				this.cotacaoRefresh.flexInferior.toString()
			)
			this.newCotacaoForm.controls['modulacao'].setValue(
				this.cotacaoRefresh.modulacao === 1 ? 'nao' : 'sim'
			)
			this.newCotacaoForm.controls['lote'].setValue(this.cotacaoRefresh.lote)
			this.newCotacaoForm.controls['observacao'].setValue(
				this.cotacaoRefresh.observacao
			)
			this.newCotacaoForm.controls['uuid'].setValue(this.cotacaoRefresh.uuid)
			this.newCotacaoForm.controls['refresh'].setValue(true)
			this.newCotacaoForm.controls['proposalCode'].setValue(
				this.cotacaoRefresh.proposalCode
			)
		}
	}

	ngAfterViewInit(): void {
		this.setMinDate()
	}

	public setMinDate() {
		setTimeout(() => {
			const maxDate = new Date()
			maxDate.setFullYear(new Date().getFullYear() + 200)
			maxDate.setHours(new Date().getHours() - 3)
			const minToday = new Date()
			minToday.setHours(new Date().getHours() - 3)
			const dataMin = document.querySelectorAll('[data-min]')
			dataMin.forEach((element: any) => {
				element.min = minToday.toISOString()
				element.max = maxDate.toISOString()
			})
			const minPastMonth = this.subtractMonths(1)
			const dataMonth = document.querySelectorAll('[data-month]')
			dataMonth.forEach((element: any) => {
				element.min = minPastMonth.toISOString()
				element.max = maxDate.toISOString()
			})
		}, 100)
	}

	private subtractMonths(numOfMonths: number, date = new Date()) {
		date.setMonth(date.getMonth() - numOfMonths)
		date.setHours(new Date().getHours() - 3)
		return date
	}

	public async closeNewCotacao(param?: string) {
		await this.modalController.dismiss(param)
	}

	public async alertFunction(title: string, msg: string, isCreate?: boolean) {
		const alert = await this.alertController.create({
			header: title,
			message: msg,
			buttons: ['OK'],
		})
		await alert.present()

		if (isCreate) {
			await alert.onDidDismiss().then(async () => {
				await this.closeNewCotacao('create')
			})
		}
	}

	public async requestCotacao() {
		if (this.newCotacaoForm.valid) {
			const that = this

			if (this.newCotacaoForm.value['quoteType'] === 'long') {
				const dataInicio = new Date(
					this.newCotacaoForm.value['inicioSuprimento']
				)
				const dataFim = new Date(this.newCotacaoForm.value['finalSuprimento'])
				if (dataFim < dataInicio) {
					return await this.alertFunction(
						'Erro',
						'A data final não pode ser menor do que a data de início de fornecimento.'
					)
				}
			}

			const minDate = new Date()
			const mandarAteDate = new Date(this.newCotacaoForm.value['mandarAte'])
			const validadeClienteDate = new Date(
				this.newCotacaoForm.value['validadeCliente']
			)

			if (mandarAteDate < minDate || validadeClienteDate < minDate) {
				return await this.alertFunction(
					'Erro',
					'A data de Quero receber até/Validade não pode ser menor do que a data atual.'
				)
			}

			await that.loaderComponent.presentLoading('Solicitando cotação...')

			try {
				this.newCotacaoForm.controls['submercado'].setValue(
					parseInt(this.newCotacaoForm.value['submercado'])
				)
				this.newCotacaoForm.controls['tipoEnergia'].setValue(
					parseInt(this.newCotacaoForm.value['tipoEnergia'])
				)
				this.newCotacaoForm.controls['pontaCliente'].setValue(
					parseInt(this.newCotacaoForm.value['pontaCliente'])
				)
				this.newCotacaoForm.controls['fixoAgio'].setValue(
					parseInt(this.newCotacaoForm.value['fixoAgio'])
				)
				this.newCotacaoForm.controls['unidadeLote'].setValue(
					parseInt(this.newCotacaoForm.value['unidadeLote'])
				)
				this.newCotacaoForm.controls['sazoInferior'].setValue(
					parseInt(this.newCotacaoForm.value['sazoInferior'])
				)
				this.newCotacaoForm.controls['sazoSuperior'].setValue(
					parseInt(this.newCotacaoForm.value['sazoInferior'])
				)
				this.newCotacaoForm.controls['flexInferior'].setValue(
					parseInt(this.newCotacaoForm.value['flexInferior'])
				)
				this.newCotacaoForm.controls['flexSuperior'].setValue(
					parseInt(this.newCotacaoForm.value['flexInferior'])
				)
				this.newCotacaoForm.controls['modulacao'].setValue(
					this.newCotacaoForm.value['modulacao'] === 'nao' ? 1 : 2
				)
				this.newCotacaoForm.controls['livreGroup'].setValue(this.group)

				if (environment.production) analytics().logEvent('request_cotacao')

				await this.quotationService.createQuotation(this.newCotacaoForm.value)
				setTimeout(function () {
					that.loaderComponent.dismissLoader()
				}, 100)
				await this.alertFunction('Sucesso', 'Cotação aberta com sucesso!', true)
			} catch (error) {
				setTimeout(function () {
					that.loaderComponent.dismissLoader()
				}, 100)
				await this.alertFunction('Erro', 'Falha na abertura da cotação!')
				throw error
			}
		} else {
			if (!this.newCotacaoForm.value['term']) {
				this.alertFunction(
					'Erro',
					'É necessário concordar com os termos e condições de cotação para prosseguir.'
				)
				document.getElementById('term').scrollIntoView({ behavior: 'smooth' })
			} else {
				this.alertFunction(
					'Erro',
					'É necessário preencher todos os campos obrigatórios.'
				)
			}
		}
	}

	public async requestCotacaoRefresh() {
		if (this.newCotacaoForm.valid) {
			const that = this
			let sazoInferior: number = 0
			let flexInferior: number = 0
			let modulacao: number = 1
			if (this.newCotacaoForm.value['quoteType'] === 'long') {
				const dataInicio = new Date(
					this.newCotacaoForm.value['inicioSuprimento']
				)
				const dataFim = new Date(this.newCotacaoForm.value['finalSuprimento'])
				sazoInferior = parseInt(this.newCotacaoForm.value['sazoInferior'])
				flexInferior = parseInt(this.newCotacaoForm.value['flexInferior'])
				modulacao = this.newCotacaoForm.value['modulacao'] === 'nao' ? 1 : 2
				if (dataFim < dataInicio) {
					return await this.alertFunction(
						'Erro',
						'A data final não pode ser menor do que a data de início de fornecimento.'
					)
				}
			} else {
				sazoInferior = 0
				flexInferior = 0
				modulacao = 1
			}

			const minDate = new Date()
			const mandarAteDate = new Date(this.newCotacaoForm.value['mandarAte'])
			const validadeClienteDate = new Date(
				this.newCotacaoForm.value['validadeCliente']
			)
			const dataInicioSuprimento = new Date(
				this.newCotacaoForm.value['inicioSuprimento']
			)

			if (mandarAteDate < minDate || validadeClienteDate < minDate) {
				return await this.alertFunction(
					'Erro',
					'A data de Quero receber até/Validade não pode ser menor do que a data atual.'
				)
			}

			await that.loaderComponent.presentLoading('Solicitando cotação...')

			try {
				this.newCotacaoForm.controls['submercado'].setValue(
					parseInt(this.newCotacaoForm.value['submercado'])
				)
				this.newCotacaoForm.controls['tipoEnergia'].setValue(
					parseInt(this.newCotacaoForm.value['tipoEnergia'])
				)
				this.newCotacaoForm.controls['pontaCliente'].setValue(
					parseInt(this.newCotacaoForm.value['pontaCliente'])
				)
				this.newCotacaoForm.controls['fixoAgio'].setValue(
					parseInt(this.newCotacaoForm.value['fixoAgio'])
				)
				this.newCotacaoForm.controls['unidadeLote'].setValue(
					parseInt(this.newCotacaoForm.value['unidadeLote'])
				)
				this.newCotacaoForm.controls['sazoInferior'].setValue(sazoInferior)
				this.newCotacaoForm.controls['sazoSuperior'].setValue(sazoInferior)
				this.newCotacaoForm.controls['flexInferior'].setValue(flexInferior)
				this.newCotacaoForm.controls['flexSuperior'].setValue(flexInferior)
				this.newCotacaoForm.controls['modulacao'].setValue(modulacao)
				this.newCotacaoForm.controls['refresh'].setValue(true)
				this.newCotacaoForm.controls['livreGroup'].setValue(this.group)
				if (environment.production) analytics().logEvent('request_cotacao')
				await this.quotationService.cotacaoRefresh(this.newCotacaoForm.value)
				setTimeout(function () {
					that.loaderComponent.dismissLoader()
				}, 100)
				await this.alertFunction(
					'Sucesso',
					'Cotação atualizada com sucesso!',
					true
				)
			} catch (error) {
				setTimeout(function () {
					that.loaderComponent.dismissLoader()
				}, 100)
				await this.alertFunction('Erro', 'Falha na atualização da cotação!')
				throw error
			}
		} else {
			if (!this.newCotacaoForm.value['term']) {
				this.alertFunction(
					'Erro',
					'É necessário concordar com os termos e condições de cotação para prosseguir.'
				)
				document.getElementById('term').scrollIntoView({ behavior: 'smooth' })
			} else {
				this.alertFunction(
					'Erro',
					'É necessário preencher todos os campos obrigatórios.'
				)
			}
		}
	}

	public async openTermos() {
		const modal = await this.modalController.create({
			component: TermoCotacaoModal,
			cssClass: 'modalStyle',
		})
		return await modal.present()
	}
}
