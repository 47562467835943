import { LeadService } from 'src/app/web/services/lead.service';
import { Router } from '@angular/router';
import { IBill } from 'src/app/models/bill';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController, AlertController, IonSlides } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WebCotacoesPage } from 'src/app/web/pages/web-cotacoes/web-cotacoes.page'

@Component({
  selector: 'app-info-analise',
  templateUrl: './info-analise.modal.html',
  styleUrls: ['./info-analise.modal.scss'],
})
export class InfoAnaliseModal implements OnInit {
  @ViewChild('pdfSlider') slider: IonSlides;

  bill: IBill
  filesUrls: SafeResourceUrl[]
  isAnalysed: boolean = false
  isAbleToDownload: boolean = false
  pendingText: string

  constructor(
    private readonly modalController: ModalController,
    private readonly router: Router,
    private readonly sanitizer: DomSanitizer,
    private readonly leadService: LeadService,
    private readonly loadingController: LoadingController,
    private readonly alertController: AlertController,
    private readonly webCotacoesPage: WebCotacoesPage
  ) { }

  public async ngOnInit() {
    const load = await this.loadingController.create({
      message: 'Carregando análise...'
    })
    this.isAnalysed = this.bill.status !== 'pending'

    if (!this.isAnalysed) {
      this.pendingText = 'Sua fatura ainda está em processo de análise e em breve retornaremos com o estudo de migração concluído.'
      return
    }

    load.present()

    await this.getSafeFileUrl().then(() => {
      this.isAbleToDownload = true
    }).catch(() => {
      this.pendingText = 'Infelizmente, não localizamos o arquivo da análise. Tente novamente mais tarde ou entre em contato conosco.'
    })

    load.dismiss()
  }

  public async closeModal(): Promise<void> {
    await this.modalController.dismiss()
  }

  public async downloadAnalysis() {
    const load = await this.loadingController.create({
      message: 'Baixando arquivo...'
    })
    load.present()
    this.leadService.downloadAnalysisFile(this.bill.id, this.bill.company).then((fileBuffer)=>{
      const blob = new Blob([new Uint8Array(fileBuffer.data)])
      const downloadURL = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = downloadURL
      link.download = `${this.bill.company}.zip`
      link.click()
      window.URL.revokeObjectURL(downloadURL)
      load.dismiss()
    }).catch((error)=>{
      load.dismiss()
      this.createAlert('Falha ao baixar arquivo.')
    })
    
  }

  public async openContact() {
    this.closeModal()
    this.webCotacoesPage.selectedPage({target: {id: 'contato'}})
    this.router.navigateByUrl("/cotacoes/contato")
  }

  protected async getSafeFileUrl(): Promise<void> {
    const partialUrls = await this.leadService.getAnalysisFileUrl(this.bill.id, this.bill.company)

    this.filesUrls = partialUrls.map(partialUrl => {
      const url = `${partialUrl}#toolbar=0&navpanes=0&view=Fit`

      return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    })
  }

  protected async createAlert(message: string){
    const alert = await this.alertController.create({
      header: 'Alerta',
      message,
      buttons: ['OK'],
    })

    return alert.present()
  }

  protected nextSlide(){
    this.slider.slideNext()
  }

  protected prevSlide(){
    this.slider.slidePrev()
  }
}