import { Injectable } from '@angular/core';
import { IZitadelUser } from 'src/app/models/zitadel';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ZitadelService {

  constructor(private readonly authenticationService: AuthenticationService) { }

  public getZitadelToken() {
    const item = sessionStorage.getItem(environment.zitadelToken)
    if (!item) return ''
    return JSON.parse(item)
  }

  public async getZitadelUser(): Promise<IZitadelUser> {
    const user = await this.authenticationService.getOIDCUser().toPromise()

    return {
      name: user.name,
      email: user.email,
      id: user.sub
    }
  }
}
