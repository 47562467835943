import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Platform, AlertController } from "@ionic/angular";
import { BackButtonEvent } from "@ionic/core";
import * as firebase from "firebase";
import "firebase/auth";
// import 'firebase/analytics';
import { DatabaseService } from "./mobile/services/database.service";
import { Prospect } from "./models/prospect";
import { AuthenticationService } from "src/shared/auth/auth.service";
import { OAuthService } from "angular-oauth2-oidc";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  private prospect: Partial<Prospect> = {
    uid: "",
    nome: "",
    email: "",
    celular: "",
    status: "",
    statusDate: "",
  };

  public hasValidAccessToken = false;
  public screenSize = window.innerWidth;

  constructor(
    private platform: Platform,
    private router: Router,
    private dbService: DatabaseService,
    private alertCtrl: AlertController,
    public auth: AuthenticationService,
    public oauthService: OAuthService,
    private gtmService: GoogleTagManagerService
  ) {
    if (!(this.platform.is("cordova") || this.platform.is("mobileweb"))) {
      if (environment.production) {
        firebase.analytics().logEvent("open_web");
      }
      this.router.navigateByUrl("/cotacoes");
    }

    this.initializeApp();
    firebase.auth().useDeviceLanguage();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (this.dbService.getOnSigningUp()) {
        return;
      }

      if (user) {
        dbService.setCurrentProspectUID(user.uid);

        if (!this.platform.is("cordova") && !this.platform.is("mobileweb")) {
          return;
        }
        firebase.analytics().logEvent("login_app");
        try {
          const prospect = await dbService.getProspect();
          this.prospect.uid = prospect.uid;
          this.prospect.email = prospect.email;
          this.prospect.nome = prospect.nome;
          this.prospect.celular = prospect.celular;
          this.prospect.status = prospect.status;
          this.dbService.setCurrentProspectUID(this.prospect.uid);
          if (prospect.resBMS && prospect.resBMS.calculo) {
            this.router.navigateByUrl("/result");
          } else if (prospect.bills) {
            this.router.navigateByUrl("/upload-bill");
          } else if (prospect.resBMS && prospect.resBMS.message) {
            this.router.navigateByUrl("/final-message");
          } else {
            this.router.navigateByUrl("/upload-bill");
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.dbService.setCurrentProspectUID("");
        if (this.platform.is("mobileweb")) {
          firebase.analytics().logEvent("open_mobileweb");
          this.router.navigateByUrl("/home");
        } else if (this.platform.is("cordova")) {
          firebase.analytics().logEvent("open_app_noUser");
          this.router.navigateByUrl("/home");
        }
      }
    });
  }

  ngOnInit(): void {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: "page",
          pageName: item.url,
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  async openAlert(res, obj) {
    const alert = await this.alertCtrl.create({
      cssClass: "alertClass",
      message: res.msg,
      buttons: [
        {
          text: "Ok",
          handler: () => {
            if (obj.platform === "android") {
              window.open(
                "https://play.google.com/store/apps/details?id=br.com.safiraenergia.obaclapp"
              );
            } else {
              window.open(
                "https://apps.apple.com/br/app/safira-livre/id1524153906?l=en"
              );
            }
          },
        },
      ],
    });
    alert.present();
  }

  async initializeApp() {
    if (!this.platform.is("cordova")) {
      return;
    }

    try {
      const obj = {
        platform: this.platform.is("android") ? "android" : "ios",
      };
      const getVersion = firebase.functions().httpsCallable("getVersion");
      const res = (await getVersion(obj)).data;

      if (res.status !== "ok") {
        return this.openAlert(res, obj);
      }
    } catch (e) {
      console.log(e);
    }

    document.addEventListener(
      "ionBackButton",
      (ev: BackButtonEvent) => {
        ev.detail.register(0, () => {
          false;
        });
      },
      false
    );
  }

  public signOut() {
    this.oauthService.logOut(true);
    window.location.href = environment.safiraonUrl;
  }
}
