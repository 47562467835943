import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { ITableOptions } from "src/app/models/table";
import { PoupupFilterComponent } from "../poupup-filter/poupup-filter.component";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit {
  @Input() options: ITableOptions;

  @Output() downloadFile = new EventEmitter<string[]>();
  @Output() downloadAllFile = new EventEmitter<string[]>();

  public ICONS_PATH = "../../../../../../assets/icon";

  public selectedFilters = [];
  public totalPages = 1;
  public currentPage = 1;
  public itemsPerPage: number;
  public currentData: any[];
  public filteredData: any[];
  public tableData: any;

  constructor(private readonly popoverController: PopoverController) {}

  ngOnInit() {
    this.tableData = this.options.table.tableData;
    this.filteredData = this.tableData.data;
    this.itemsPerPage = this.options.itemsPerPage ?? 10
    this.resetTableData();
  }

  public async openFilter(e: any) {
    const filterEmitter = new EventEmitter<any>();
    filterEmitter.subscribe((ev) => {
      this.selectFieldFilter(ev);
    });
    const popover = await this.popoverController.create({
      component: PoupupFilterComponent,
      componentProps: {
        selectFieldFilter: filterEmitter,
        options: this.options,
        selectedFilters: this.selectedFilters,
      },
      event: e,
    });
    await popover.present();
  }

  public selectFieldFilter(ev: any) {
    const { checked, value } = ev.detail;
    if (!this.selectedFilters) {
      this.selectedFilters = [...this.options.table.tableData.filterOptions];
    }

    if (checked) {
      this.selectedFilters.push(value);
      this.filterByFields();
      return;
    }

    const index = this.selectedFilters.indexOf(value);
    if (index > -1) {
      this.selectedFilters.splice(index, 1);
    }
    this.filterByFields();
    if (this.currentPage > this.totalPages) {
      this.selectPageByNumber(1);
    }
  }

  public selectPageByNumber(page: number) {
    if (page === this.currentPage) {
      return;
    }
    if (page > this.totalPages || page < 1) {
      return;
    }
    this.currentPage = page;
    this.resetTableData();
  }

  public async nextPage() {
    if (this.currentPage === this.totalPages) {
      return;
    }
    this.currentPage++;
    this.resetTableData();
  }

  public async previousPage() {
    if (this.currentPage === 1) {
      return;
    }
    this.currentPage--;
    this.resetTableData();
  }

  public selectPage(ev: any) {
    const page = Number(ev.target.value);
    if (page === this.currentPage) {
      return;
    }
    if (page > this.totalPages || page < 1) {
      return;
    }
    this.currentPage = page;
    this.resetTableData();
  }

  public filterBySearch(event: any) {
    this.filteredData = this.tableData.data.filter((val) => {
      return val[this.options.search.field]
        .toLowerCase()
        .startsWith(event.detail.value.toLowerCase());
    });
    this.resetTableData();
    if (this.currentPage > this.totalPages) {
      this.selectPageByNumber(1);
    }
  }

  public sortBy(ev: any, field: string) {
    const order = ev.target.dataset.order;
    if (order === "asc") {
      ev.target.dataset.order = "desc";
      ev.target.src = this.ICONS_PATH + "/sort-down-solid.svg";
      this.filteredData = this.tableData.data.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        } else if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
      this.resetTableData();
      return;
    }
    ev.target.dataset.order = "asc";
    ev.target.src = this.ICONS_PATH + "/sort-up-solid.svg";
    this.filteredData = this.tableData.data.sort((a, b) => {
      if (a[field] < b[field]) {
        return 1;
      } else if (a[field] > b[field]) {
        return -1;
      }
      return 0;
    });
    this.resetTableData();
    return;
  }

  private resetTableData() {
    this.totalPages = Math.ceil(this.filteredData.length / this.itemsPerPage) || 1;
    const pageIndex = (this.currentPage - 1) * this.itemsPerPage;
    if (this.currentPage === this.totalPages) {
      this.currentData = this.filteredData.slice(
        pageIndex,
        this.filteredData.length
      );
      return;
    }
    this.currentData = this.filteredData.slice(pageIndex, pageIndex + this.itemsPerPage);
  }

  private filterByFields() {
    if(!this.selectedFilters.length) {
      this.filteredData = this.options.table.tableData.data;
      this.resetTableData();
      return
    }
    this.filteredData = this.options.table.tableData.data.filter((data) =>
      this.selectedFilters.includes(data[this.options.filter.filterField])
    );
    this.resetTableData();
  }
}
