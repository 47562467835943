import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quotationSubmarket'
})

export class QuotationSubmarketPipe implements PipeTransform {
  transform(value: number): string {
    if (value === 1) {
      return 'Sudeste'
    } else if (value === 2) {
      return 'Sul'
    } else if (value === 3) {
      return 'Nordeste'
    } else if (value === 4) {
      return 'Norte'
    }
  }
}
