import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { FooterComponent } from "src/app/web/components/footer/footer.component";
import { LoadingComponent } from "src/app/web/components/loading/loading.component";
import { PoupupFilterComponent } from "src/app/web/components/poupup-filter/poupup-filter.component";
import { TableComponent } from "src/app/web/components/table/table.component";
import { QuotationStatusPipe } from "src/app/web/pipes/quotation-status.pipe";
import { QuotationSubmarketPipe } from "src/app/web/pipes/quotation-submarket.pipe";
import { QuotationTypePipe } from "src/app/web/pipes/quotation-type.pipe";

@NgModule({
  declarations: [
    QuotationTypePipe,
    QuotationStatusPipe,
    QuotationSubmarketPipe,
    FooterComponent,
    LoadingComponent,
    TableComponent,
    PoupupFilterComponent,
  ],
  exports: [
    QuotationTypePipe,
    QuotationStatusPipe,
    QuotationSubmarketPipe,
    FooterComponent,
    LoadingComponent,
    TableComponent,
  ],
  imports: [CommonModule, IonicModule],
})
export class SharedModule {}
