import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Component({
    selector: 'app-loader-component',
    template: '<div></div>'
})
export class LoaderComponent {

    constructor(private loadingController: LoadingController) { }

    public async presentLoading(msg: string) {
        this.loadingController.create({
            message: msg
        }).then((response) => {
            response.present()
        })
    }

    public async dismissLoader() {
        await this.loadingController.dismiss()
    }
}