import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quotationType'
})

export class QuotationTypePipe implements PipeTransform {
  transform(value: number): string {
    if (value === 1) {
      return 'Conv.'
    } else if (value === 2) {
      return 'Incent. 50%'
    } else if (value === 3) {
      return 'Incent. 100%'
    } else if (value === 4) {
      return 'Incent. 0%'
    } else if (value === 5) {
      return 'C.Q. 50%'
    } else if (value === 6) {
      return 'C.Q. 100%'
    }
  }
}
