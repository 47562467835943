import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: "app-info-feedback",
  templateUrl: "./info-feedback.modal.html",
  styleUrls: ["./info-feedback.modal.scss"]
})
export class InfoFeedbackModal implements OnInit {
  public prazo: string;
  public quotationId: string;
  public livreUserResponse: string;
  public validade: string;

  constructor(
    private modalController: ModalController,
    public navParams: NavParams
  ) {
    this.quotationId = navParams.get("quotationId")
    this.livreUserResponse = navParams.get("livreUserResponse")
    this.validade = navParams.get("validade")
  }

  public ngOnInit(): void { }

  public async closeModal(param?: any): Promise<void> {
    await this.modalController.dismiss(param)
  }

  public async rejectQuote(motivoPerda, precoPerda, motivoPerdaOutro, observacao, cotacao): Promise<void> {
    const data = {
      quotationId: this.quotationId,
      livreUserResponse: this.livreUserResponse,
      validade: this.validade,
      motivoPerda,
      precoPerda,
      motivoPerdaOutro,
      observacao,
      cotacao
    }
    this.closeModal(data)
  }
}
