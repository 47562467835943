import { IBill } from 'src/app/models/bill';
import axios from 'axios';
import { ZitadelService } from './zitadel.service';
import { environment } from "src/environments/environment";
import { Injectable } from '@angular/core';
import { storage } from 'firebase'
@Injectable({
    providedIn: 'root'
})

export class LeadService {
    constructor(private readonly zitadelService: ZitadelService) { }

    private readonly FIREBASE_FUNCTIONS_URL = environment.firebaseFunctionsURL;

    public async postLeadAndInvoice(formData: FormData) {
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: this.zitadelService.getZitadelToken()
        };

        return await axios.post(this.FIREBASE_FUNCTIONS_URL + "/postLeadAndInvoice", formData, { headers })
    }

    public async getInvoiceAnalysis(email: string): Promise<IBill[]> {
        const headers = {
            Authorization: this.zitadelService.getZitadelToken()
        };
        const response = await axios.get(this.FIREBASE_FUNCTIONS_URL + `/getInvoiceAnalysis?email=${email}`, { headers })
        return response.data.data
    }

    public async getAnalysisFileUrl(dealId: number, company: string): Promise<string[]> {
        const headers = {
            Authorization: this.zitadelService.getZitadelToken()
        };
        const response = await axios.get(this.FIREBASE_FUNCTIONS_URL + `/getAnalysisFileUrl?id=${dealId}&company=${company}`, { headers })
        
        return response.data.data
    }

    public async downloadAnalysisFile(id: number, company: string) {
        const headers = {
            Authorization: this.zitadelService.getZitadelToken(),
        }
        return (await axios
            .get(this.FIREBASE_FUNCTIONS_URL + "/downloadAnalysisFile", {
                headers,
                params: { id, company },
            })).data.data
    }

    public async downloadAllAnalysisFiles(companyArray: {invoiceId: number, company: string}[]) {
        const headers = {
            Authorization: this.zitadelService.getZitadelToken(),
        }
        
        return (await axios
            .post(this.FIREBASE_FUNCTIONS_URL + "/downloadAllAnalysisFiles", {
                headers,
                params: { companyArray },
            })).data.data
    }
}