import { Component } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CotacoesService } from 'src/app/web/services/Quotation.service';
import { LoaderComponent } from 'src/shared/loader/loader.component';

@Component({
  selector: 'app-confirm-signature',
  templateUrl: './confirm-signature.modal.html',
  styleUrls: ['./confirm-signature.modal.scss'],
})
export class ConfirmSignatureModal {

  public showPassword: boolean = false
  public passwordToggleIcon = 'eye'
  public signature: string
  public recoverySignature: boolean = false
  public code: string = ''

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private cotacoesService: CotacoesService,
    private loading: LoaderComponent
  ) { }

  public async closeModal(created?: any): Promise<void> {
    await this.modalController.dismiss(created)
  }

  public togglePassword(): void {
    this.showPassword = !this.showPassword

    if (this.passwordToggleIcon === 'eye') this.passwordToggleIcon = 'eye-off'
    else this.passwordToggleIcon = 'eye'
  }

  public async confirmSignature(): Promise<void> {
    const that = this
    if (this.signature.length !== 6) {
      await this.createAlert('Erro',
        'Sua assinatura precisa de 6 dígitos, tente novamente.')
    } else {
      that.loading.presentLoading('Enviando assinatura')
      this.cotacoesService.isSignature(this.signature)
        .then(async (data) => {
          that.loading.dismissLoader()
          if (data.confirmed) await this.closeModal(data)
          else await this.createAlert('Erro', 'A assinatura está incorreta, tente novamente.')
        })
        .catch(async () => {
          that.loading.dismissLoader()
          await this.createAlert('Erro', 'Não foi possível confirmar sua assinatura, tente novamente.')
        })
    }
  }

  public async createAlert(title: string, msg: string): Promise<void> {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: ['OK']
    })
    return alert.present()
  }

  public requestNewSignature(): void {
    this.recoverySignature = true
    this.cotacoesService.sendRecoveryCode()
    this.signature = ''
    this.code = ''
  }

  public async createNewSignature(): Promise<void> {
    const that = this
    if (this.code.length < 1) {
      return await this.createAlert('Erro',
        'Digite o código de confirmação.')
    }

    if (this.signature.length !== 6) {
      return await this.createAlert('Erro',
        'Sua assinatura precisa de 6 dígitos, tente novamente.')
    }

    that.loading.presentLoading('Criando assinatura')
    await this.cotacoesService.matchRecoveryCode(this.code, this.signature)
      .then(async (res) => {
        that.loading.dismissLoader()
        if (res.response) {
          await this.createAlert('Sucesso', res.message)
          this.signature = ''
          this.code = ''
          this.recoverySignature = false
        } else {
          await this.createAlert('Erro', res.message)
        }
      })
      .catch(async () => {
        that.loading.dismissLoader()
        await this.createAlert('Erro',
          'Não foi possível alterar sua assinatura, tente novamente.')
      })
  }
}
