import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import * as firebase from 'firebase/app';
import { NewCotacaoModal } from './web/pages/web-cotacoes/subpages/web-cotacoes-lista/new-cotacao/new-cotacao.modal';
import { TermoCotacaoModal } from './web/pages/web-cotacoes/subpages/web-cotacoes-lista/termo-cotacao/termo-cotacao.modal';
import { StatehandlerService, StatehandlerServiceImpl } from 'src/shared/auth/statehandler.service';
import { StatehandlerProcessorService, StatehandlerProcessorServiceImpl } from 'src/shared/auth/statehandler-processor.service';
import { StorageService } from 'src/shared/auth/storage.service';
import { LoaderComponent } from 'src/shared/loader/loader.component';
import localePt from '@angular/common/locales/pt'
import { registerLocaleData } from '@angular/common';
import { InfoCotacaoModal } from './web/pages/web-cotacoes/subpages/web-cotacoes-lista/info-cotacao/info-cotacao.modal';
import { InfoAnaliseModal } from './web/pages/web-cotacoes/subpages/web-cotacoes-analises/web-minhas-analises/info-analise/info-analise.modal'
import { SharedModule } from 'src/shared/shared-module/shared.module';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NewSignatureModal } from './web/pages/web-cotacoes/subpages/web-cotacoes-lista/new-signature/new-signature.modal';
import { ConfirmSignatureModal } from './web/pages/web-cotacoes/subpages/web-cotacoes-lista/confirm-signature/confirm-signature.modal';
import { InfoFeedbackModal } from './web/pages/web-cotacoes/subpages/web-cotacoes-lista/info-feedback/info-feedback.modal';

const app = firebase.initializeApp(environment.firebase);
//firebase.functions().useFunctionsEmulator("http://localhost:5001"); // TODO disable when it's running on production

if (environment.production) {
  firebase.analytics(app);
}

const urlParams = new URLSearchParams(window.location.search)
const userParam: string = urlParams.get('user')
let authConfig: AuthConfig;
if (userParam) {
  authConfig = {
    customQueryParams: {
      login_hint: userParam,
    },
    ...environment.zitadel
  }
} else {
  authConfig = environment.zitadel
}

const stateHandlerFn = (stateHandler: StatehandlerService) => {
  return () => {
    return stateHandler.initStateHandler();
  };
};

registerLocaleData(localePt, 'pt')

@NgModule({
  declarations: [
    AppComponent,
    NewCotacaoModal,
    InfoCotacaoModal,
    TermoCotacaoModal,
    NewSignatureModal,
    LoaderComponent,
    ConfirmSignatureModal,
    InfoFeedbackModal,
    InfoAnaliseModal,
  ],
  entryComponents: [
    NewCotacaoModal,
    InfoCotacaoModal,
    TermoCotacaoModal,
    NewSignatureModal,
    ConfirmSignatureModal,
    InfoFeedbackModal,
    InfoAnaliseModal
  ],
  imports: [
    BrowserModule,
    SharedModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgxDropzoneModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          '<YOUR DOMAIN>/caos.zitadel.auth.api.v1.AuthService',
          '<YOUR DOMAIN>/oauth/v2/userinfo',
          '<YOUR DOMAIN>/caos.zitadel.management.api.v1.ManagementService/',
          'https://preview.api.zitadel.caos.ch'],
        sendAccessToken: true,
      },
    })
  ],
  providers: [
    {
      provide: 'googleTagManagerId',
      useValue: environment.analytics.gtmId
    },
    GoogleTagManagerService,
    {
      provide: APP_INITIALIZER,
      useFactory: stateHandlerFn,
      multi: true,
      deps: [StatehandlerService],
    },
    {
      provide: AuthConfig,
      useValue: authConfig,
    },
    {
      provide: StatehandlerProcessorService,
      useClass: StatehandlerProcessorServiceImpl,
    },
    {
      provide: StatehandlerService,
      useClass: StatehandlerServiceImpl,
    },
    {
      provide: OAuthStorage,
      useClass: StorageService,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    LoaderComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
