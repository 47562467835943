import { ZitadelService } from './zitadel.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { ICotacao } from 'src/app/models/cotacao';
import { AuthenticationService } from 'src/shared/auth/auth.service';

import FileSaver from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class CotacoesService {

  constructor(
    private authenticateService: AuthenticationService,
    private router: Router,
    private readonly zitadelService: ZitadelService
  ) { }

  public async getGroupQuotations() {
    const token = this.zitadelService.getZitadelToken()
    let user: any
    try {
      user = await this.authenticateService.getOIDCUser().toPromise()
    } catch (error) {
      this.authenticateService.signout()
      this.router.navigateByUrl('')
    }
    const getQuotations = firebase.functions().httpsCallable('listUser')
    const response = (await getQuotations({ email: user.email, token })).data
    return response.res
  }

  public async createQuotation(quotation: Partial<ICotacao>): Promise<any> {
    const token = this.zitadelService.getZitadelToken()
    let user: any
    try {
      user = await this.authenticateService.getOIDCUser().toPromise()
    } catch (error) {
      this.authenticateService.signout()
      this.router.navigateByUrl('')
    }
    quotation.livreUser = user.email
    const quotationResponse = firebase.functions().httpsCallable('create');
    const response = (
      await quotationResponse({
        quotation,
        token
      })
    ).data;
    return response
  }

  public async listUserCompanies(): Promise<any[]> {
    const token = this.zitadelService.getZitadelToken()
    let user: any
    try {
      user = await this.authenticateService.getOIDCUser().toPromise()
    } catch (error) {
      this.authenticateService.signout()
      this.router.navigateByUrl('')
    }
    const quotationResponse = firebase.functions().httpsCallable('listUserCompanies');
    const response = (
      await quotationResponse({ email: user.email, token })
    ).data;
    if (response.status !== 200) {
      throw new Error(response.error)
    }
    return response
  }

  public async acceptOrReject(proposalCode: string, livreUserResponse: string, motivoPerda?: string, precoPerda?: string, motivoPerdaOutro?: string, observacao?: string, cotacaoUuid?: string) {
    const token = this.zitadelService.getZitadelToken()
    let user: any
    try {
      user = await this.authenticateService.getOIDCUser().toPromise()
    } catch (error) {
      this.authenticateService.signout()
      this.router.navigateByUrl('')
    }
    const update = {
      proposalCode: proposalCode,
      livreUserResponse: livreUserResponse,
      livreUser: user.email,
      motivoPerda: motivoPerda,
      precoPerda: precoPerda,
      motivoPerdaOutro: motivoPerdaOutro,
      observacao: observacao,
      cotacaoUuid: cotacaoUuid,
    }
    const quotationResponse = firebase.functions().httpsCallable('update');
    const response = (
      await quotationResponse({
        update,
        token
      })
    ).data;
    return response
  }

  
    public async cotacaoRefresh(quotation: any) {
    const token = this.zitadelService.getZitadelToken()
    let user: any
    try {
      user = await this.authenticateService.getOIDCUser().toPromise()
    } catch (error) {
      this.authenticateService.signout()
      this.router.navigateByUrl('')
    }
    const update = {
      contraparte: quotation.contraparte,
      finalSuprimento: quotation.finalSuprimento,
      fixoAgio: quotation.fixoAgio,
      flexInferior: quotation.flexInferior,
      flexSuperior: quotation.flexSuperior,
      inicioSuprimento: quotation.inicioSuprimento,
      livreGroup: quotation.livreGroup,
      lote: quotation.lote,
      mandarAte: quotation.mandarAte,
      modulacao: quotation.modulacao,
      observacao: quotation.observacao,
      pontaCliente: quotation.pontaCliente,
      quoteType: quotation.quoteType,
      sazoInferior: quotation.sazoInferior,
      sazoSuperior: quotation.sazoSuperior,
      submercado: quotation.submercado,
      term: quotation.term,
      tipoEnergia: quotation.tipoEnergia,
      unidadeLote: quotation.unidadeLote,
      cotacaoUuid: quotation.uuid,
      validadeCliente: quotation.validadeCliente,
      refresh: quotation.refresh,
      livreUser: user.email,
      proposalCode:quotation.proposalCode
    }
    const quotationResponse = firebase.functions().httpsCallable('update');
    const response = (
      await quotationResponse({
        update,
        token
      })
    ).data;
    return response
  }

  public async sendEmail(params: any) {
    const token = this.zitadelService.getZitadelToken()
    const user = await this.authenticateService.getOIDCUser().toPromise()
    const email = { email: user.email, ...params }
    const emailResponse = firebase.functions().httpsCallable('sendEmail')
    const response = (
      await emailResponse({
        email,
        token
      })
    ).data;
    return response
  }

  public async sendStatus() {
    let userId: any
    try {
      userId = await this.authenticateService.getOIDCUser().toPromise()
    } catch (error) {
      this.authenticateService.signout()
      this.router.navigateByUrl('')
    }
    const result = await firebase.firestore().collection('Migracao').where('id', '==', userId.sub).get()
    if (result.docs.length > 0) {
      return result.docs[0].data().phase
    } else {
      return "error"
    }
  }

  public async getIndicativePrices(curveType: string) {
    const indicativeResponse = firebase.functions().httpsCallable('getIndicativePrices')
    const response = (await indicativeResponse({ curveType })).data
    return response
  }

  public async getDownloadPropostas(cotacao: any) {
    const token = this.zitadelService.getZitadelToken()
    const cotacaoProposalCode = cotacao.cotacao_proposalCode
    const downloadResponse = firebase.functions().httpsCallable('getDownloadProposta')
    const response = (await downloadResponse({ cotacao, token })).data.download
    const arrayBuffer = [new Uint8Array(response)]
    const blobProposta = new Blob(arrayBuffer, { type: 'application/pdf' })
    FileSaver.saveAs(blobProposta, `Proposta-${cotacaoProposalCode}.pdf`)
    return response
  }

  public async sendRecoveryCode() {
    const token = this.zitadelService.getZitadelToken()
    const downloadResponse = firebase.functions().httpsCallable('recoveryCode')
    const response = (await downloadResponse({ token })).data
    return response
  }

  public async matchRecoveryCode(code: string, signature: string): Promise<any> {
    const token = this.zitadelService.getZitadelToken()
    const downloadResponse = firebase.functions().httpsCallable('matchCode')
    const response = (await downloadResponse({ token, code, signature })).data
    return response.result
  }

  public async hasSignature() {
    let userId: any
    try {
      userId = await this.authenticateService.getOIDCUser().toPromise()
    } catch (error) {
      this.authenticateService.signout()
      this.router.navigateByUrl('')
    }
    const result = await firebase.firestore().collection('Signatures').where('id', '==', userId.sub).get()

    if (!result.docs.length) {
      return 'blank'
    }
  }

  public async createSignature(signature: string): Promise<any> {
    const token = this.zitadelService.getZitadelToken()
    try {
      await this.authenticateService.getOIDCUser().toPromise()
    } catch (error) {
      this.authenticateService.signout()
      this.router.navigateByUrl('')
    }
    const signatureResponse = firebase.functions().httpsCallable('createSignature');
    const response = (
      await signatureResponse({
        signature,
        token
      })
    ).data;
    return response
  }

  public async isSignature(signature: string) {
    const token = this.zitadelService.getZitadelToken()
    try {
      await this.authenticateService.getOIDCUser().toPromise()
    } catch (error) {
      this.authenticateService.signout()
      this.router.navigateByUrl('')
    }
    const signatureResponse = firebase.functions().httpsCallable('confirmSignature');
    const response = (
      await signatureResponse({
        signature,
        token
      })
    ).data;
    return response
  }
}
