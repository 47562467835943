import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Prospect } from 'src/app/models/prospect';
import { FriendlyError } from 'src/app/utilsGeneric/Friendly.error';
import { validResponseBMS } from 'src/app/utilsGeneric/typeResponseBMS.util';
import { FirebaseAdapter } from '../../utilsGeneric/Firebase.adapter';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private onSigningUp = false;

  result = false;

  public currentProspect: Partial<Prospect> = {
    uid: ''
  };

  public isLoggedSubject = new BehaviorSubject<boolean>(false);

  constructor() { }

  setCurrentProspectUID(uid: string) {
    this.currentProspect.uid = uid;
    this.isLoggedSubject.next(true);
  }

  async addTechnicalInfo(techInfo: Partial<Prospect>) {
    const prospectRef = firebase.firestore().collection('Prospect').doc(techInfo.uid);

    await prospectRef.set(
      {
        uf: techInfo.uf,
        distribuidora: techInfo.distribuidora,
        consumoPonta: techInfo.consumoPonta,
        consumoFPonta: techInfo.consumoFPonta,
        demandaPonta: techInfo.demandaPonta,
        demandaFPonta: techInfo.demandaFPonta,
        nivelTensao: techInfo.nivelTensao,
        modalidadeTarifaria: techInfo.modalidadeTarifaria,
        status: techInfo.status
      },
      { merge: true }
    );
  }

  async addProspect(prospect: Partial<Prospect>): Promise<any> {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    const addProspectMobile = firebase.functions().httpsCallable('addProspectMobile');
    const response = (
      await addProspectMobile({
        prospect
      })
    ).data;
    this.handleResponseError(response);

    this.setCurrentProspectUID(prospect.uid);
  }

  async updateProspect(prospect: Partial<Prospect>) {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    const updateProspectMobile = firebase.functions().httpsCallable('updateProspectMobile');
    const response = (
      await updateProspectMobile({
        prospect
      })
    ).data;
    this.handleResponseError(response);
  }

  async getResponseBMS() {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    const getResponseBMS = firebase.functions().httpsCallable('getResponseBMS');
    const response = (await getResponseBMS()).data;
    this.handleResponseError(response);

    return response.data;
  }

  async getProspect() {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    const getProspect = firebase.functions().httpsCallable('getProspectMobile');
    const response = (await getProspect()).data;
    this.handleResponseError(response);

    return response.data;
  }

  async requestBMS(prospectBMS: Partial<Prospect>): Promise<validResponseBMS | boolean> {
    prospectBMS.consumoPonta = (+prospectBMS.consumoPonta / 1000).toString();
    prospectBMS.consumoFPonta = (+prospectBMS.consumoFPonta / 1000).toString();

    const requestBMS = firebase.functions().httpsCallable('requestBMS');
    const { data } = await FirebaseAdapter(requestBMS(prospectBMS));

    if (data && data.message && data.message.match(/Tarifas/)) {
      throw new FriendlyError('Nível de tensão e/ou Mod. tarifária estão incorretos');
    }

    if (!data || data.message) {
      throw new Error('Houve um problema, tente novamente mais tarde');
    }

    return data;
  }

  async getDistribuidoras() {
    const getDistribuidoras = firebase.functions().httpsCallable('getDistribuidoras');
    const response = (await getDistribuidoras()).data;
    this.handleResponseError(response);

    return response.data;
  }

  async updataStatusProspect(status: string) {
    let prospectRef = firebase.firestore().collection('Prospect').doc(this.currentProspect.uid);
    await prospectRef.update({
      status: status,
      statusDate: moment().toISOString()
    });
  }

  setOnSigningUp(state: boolean) {
    this.onSigningUp = state;
  }

  getOnSigningUp(): boolean {
    return this.onSigningUp;
  }

  async addBillProspect(filesPrev: any) {
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
    const addBillsProspectMobile = firebase.functions().httpsCallable('addBillsProspectMobile');
    const response = (
      await addBillsProspectMobile({
        filesPrev
      })
    ).data;
    this.handleResponseError(response);
  }

  setProspectResult() {
    this.result = true;
  }

  async getProspectResult() {
    return this.result;
  }

  moneyFormat(value: number) {
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL'
    });
  }

  percentFormat(value: number) {
    return value.toLocaleString('pt-br', {
      style: 'percent',
      minimumFractionDigits: 2
    });
  }

  handleResponseError(response: any) {
    if (response.status === 'error') {
      console.log(response);

      this.responseError(response);
    }
  }

  async responseError(error: any) {
    alert(error.message || 'Ocorreu um erro inesperado');
  }
}
