import { DOCUMENT } from '@angular/common'
import { Component, Inject, Injectable, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { analytics } from 'firebase'
import { environment } from '../../../../environments/environment'
import { AuthenticationService } from 'src/shared/auth/auth.service'
import { CotacoesService } from '../../services/Quotation.service'
import { AlertController } from '@ionic/angular'

@Component({
	selector: 'app-web-cotacoes',
	templateUrl: './web-cotacoes.page.html',
	styleUrls: ['./web-cotacoes.page.scss'],
})
@Injectable({
	providedIn: 'root',
})
export class WebCotacoesPage implements OnInit {
	env = environment
	activeClass: string = 'activeHighlight'
	nameUserActive: string
	isLoading: boolean = true

	group = ''
	companiesList = []

	constructor(
		private alertController: AlertController,
		public router: Router,
		public authService: AuthenticationService,
		public cotacoesService: CotacoesService,
		@Inject(DOCUMENT) public document: Document
	) {}

	ngOnInit() {
		if (environment.production) analytics().logEvent('login_cotacao')

		this.buscarUser()
		this.redirect()
	}

	public selectedPage(event) {
		const menuOptions = document.querySelectorAll('.page-button-class') as any
		menuOptions.forEach((option) => {
			if (event.target.id === option.id) {
				option.classList.add(this.activeClass)
			} else {
				option.classList.remove(this.activeClass)
			}
		})

		let queryParams = {}
		if (event.target.id === 'lista' || event.target.id === 'analises') {
			queryParams = {
				group: this.group,
				companiesList: JSON.stringify(this.companiesList),
			}
		}

		this.router.navigate([`/cotacoes/${event.target.id}`], { queryParams })
	}

	public async buscarUser() {
		const user = await this.authService.getOIDCUser().toPromise()
		this.nameUserActive = user.name
	}

	public logout(): void {
		if (environment.production) analytics().logEvent('logout_cotacao')

		this.authService.signout()
		this.router.navigateByUrl('')
	}

	private async redirect() {
		await this.getUserGroup()
		this.isLoading = false
		const queryParams = {
			group: this.group,
			companiesList: JSON.stringify(this.companiesList),
		}

		if (this.companiesList.length > 0) {
			this.router.navigate(['/cotacoes/lista'], {
				queryParams,
			})
		} else {
			this.router.navigate(['/cotacoes/contato'])
		}
	}

	private async getUserGroup(): Promise<void> {
		await this.cotacoesService
			.listUserCompanies()
			.then((response) => {
				this.group = response['result'][0]['groupId']
				this.companiesList = response['result']
					.map((group) => {
						return group.companies
					})
					.flat()
			})
			.catch(async () => {
				const alert = await this.createAlert(
					'Erro',
					`Sua empresa ainda não foi cadastrada, favor entrar em contato
					conosco pelo formulário, com os dados da empresa, que finalizaremos 
					seu cadastro.`
				)
				await alert.present()
			})
	}

	public async createAlert(title: string, msg: string, isUpdate?: boolean) {
		const alert = await this.alertController.create({
			header: title,
			message: msg,
			buttons: ['OK'],
		})

		return alert
	}
}
