import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-termo-cotacao',
  templateUrl: './termo-cotacao.modal.html',
  styleUrls: ['./termo-cotacao.modal.scss'],
})
export class TermoCotacaoModal {
  constructor(private modalController: ModalController) { }

  public async closeTermoCotacao(param?: string) {
    await this.modalController.dismiss(param);
  }
}