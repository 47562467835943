import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'quotationStatus'
})

export class QuotationStatusPipe implements PipeTransform {
  transform(value: number): string {
    if (value === 0) {
      return 'Aguardando'
    } else if (value === 1) {
      return 'Aguardando'
    } else if (value === 2) {
      return 'Aguardando'
    } else if (value === 3) {
      return 'Precificada'
    } else if (value === 4) {
      return 'Vencida'
    } else if (value === 5) {
      return 'Aprovada'
    } else if (value === 6) {
      return 'Rejeitada'
    } else if (value === 7) {
      return 'Em revisão'
    } else if (value === 8) {
      return 'Cancelada'
    } else if (value === 9) {
      return 'Conferida'
    } else if (value === 10) {
      return 'Declinada'
    } else if (value === 11) {
      return 'Declinada pelo cliente'
    } else if (value === 12) {
      return 'Em standby'
    }
  }
}