import { to } from './Async.util';

export async function FirebaseAdapter(fn) {
  const [error, response] = await to(fn);
  if (error) {
    throw Error('Houve um erro');
  }

  return response;
}
