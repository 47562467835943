import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public tos: string = environment.terms.tos
  public privacy: string = environment.terms.privacy

  constructor() { }

  ngOnInit() { }

  redirectUrl(param: string) {
    if (param === 'facebook') window.open('https://pt-br.facebook.com/SafiraEnergiaCom', '_blank')
    else if (param === 'instagram') window.open('https://www.instagram.com/safira.energia', '_blank')
    else if (param === 'linkedin') window.open('https://www.linkedin.com/company/gruposafira', '_blank')
    else if (param === 'youtube') window.open('https://www.youtube.com/channel/UCQvNoQXMfUoVUuVVobRkT5g', '_blank')
  }
}
