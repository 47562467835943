import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from 'src/shared/auth/auth.guard';

const routes: Routes = [
  {
    path: 'cotacoes',
    loadChildren: () => import('./web/pages/web-cotacoes/web-cotacoes.module').then(m => m.WebCotacoesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'cotacoes',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }