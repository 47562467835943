import { Component } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CotacoesService } from 'src/app/web/services/Quotation.service';
import { LoaderComponent } from 'src/shared/loader/loader.component';

@Component({
  selector: 'app-new-signature',
  templateUrl: './new-signature.modal.html',
  styleUrls: ['./new-signature.modal.scss'],
})
export class NewSignatureModal {

  public showPassword: boolean = false
  public passwordToggleIcon = 'eye'
  public signature: string

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private cotacoesService: CotacoesService,
    private loading: LoaderComponent
  ) { }

  public async closeModal(param?: string): Promise<void> {
    await this.modalController.dismiss(param)
  }

  public togglePassword(): void {
    this.showPassword = !this.showPassword

    if (this.passwordToggleIcon === 'eye') this.passwordToggleIcon = 'eye-off'
    else this.passwordToggleIcon = 'eye'
  }

  public async createSignature(): Promise<void> {
    const that = this
    if (this.signature.length !== 6) {
      await this.createAlert('Erro',
        'Sua assinatura precisa de 6 dígitos, tente novamente.', false)
    } else {
      that.loading.presentLoading('Criando assinatura')
      this.cotacoesService.createSignature(this.signature)
        .then(async () => {
          that.loading.dismissLoader()
          await this.createAlert('Sucesso',
            'Sua assinatura foi criada com sucesso.', true)
        })
        .catch(async () => {
          that.loading.dismissLoader()
          await this.createAlert('Erro',
            'Não foi possível criar sua assinatura, tente novamente.', false)
        })
    }
  }

  public async createAlert(title: string, msg: string, created: boolean) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: ['OK']
    })

    alert.onDidDismiss().then(async () => {
      if (created) await this.closeModal('create')
    })

    return alert.present()
  }

}