import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICotacao } from 'src/app/models/cotacao';
import * as moment from 'moment';
import { CotacoesService } from 'src/app/web/services/Quotation.service';

@Component({
  selector: 'app-info-cotacao',
  templateUrl: './info-cotacao.modal.html',
  styleUrls: ['./info-cotacao.modal.scss'],
})
export class InfoCotacaoModal implements OnInit {

  public quotation: ICotacao
  public prazo: string

  constructor(
    private modalController: ModalController,
    private cotacoesService: CotacoesService
  ) { }

  public ngOnInit(): void {
    this.prazo = moment(this.quotation.inicioSuprimento).format('MM/yyyy') === moment(this.quotation.finalSuprimento).format('MM/yyyy') ? 'Curto prazo' : 'Longo prazo'
  }

  public async closeInfoCotacao(): Promise<void> {
    await this.modalController.dismiss()
  }

  public async downloadProposta(cotacao: ICotacao,) {
    const cotacaoProposta = {
      cotacao_uuid: cotacao.uuid,
      cotacao_preco: cotacao.precoVenda,
      cotacao_validade: cotacao.validade,
      cotacao_proposalCode: cotacao.proposalCode,
    }
    let file = await this.cotacoesService.getDownloadPropostas(cotacaoProposta)
  }

}