import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ITableOptions } from "src/app/models/table";

@Component({
  selector: "app-poupup-filter",
  templateUrl: "./poupup-filter.component.html",
  styleUrls: ["./poupup-filter.component.scss"],
})
export class PoupupFilterComponent {
  @Input() options: ITableOptions;
  @Input() selectedFilters = [];

  @Output() selectFieldFilter = new EventEmitter<any>();

  constructor() {}

  public selectFilter(ev: any) {
    this.selectFieldFilter.emit(ev);
  }
}
